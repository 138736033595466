import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { showAlertMessage } from '../helpers/utils';

@Injectable({
    providedIn: 'root'
})
export class PurchaseHistoryService {

    constructor(
        public http: HttpClient,
    ) { }

    private handleError(error: any) {
        if (error.error instanceof ErrorEvent) {
            showAlertMessage(error.error.message ?? "Something went wrong!", "error");
        } else {
            showAlertMessage(error ?? "Something bad happened; please try again later.", "error");
        }
        return throwError(() => 'Something bad happened; please try again later.');
    }

    getOrganizationsForPurchase(payload: { purchaseType: string }): Observable<any> {
        return this.http.get<any>(`${environment.API}organization/purchase-history?purchaseType=${payload?.purchaseType}&skip=0&limit=500`).pipe(catchError(this.handleError))
    }
    getHouseholdMembersForPurchase(payload: { purchaseType: string }): Observable<any> {
        return this.http.get<any>(`${environment.API}purchased/users?purchaseType=${payload?.purchaseType}&skip=0&limit=500`).pipe(catchError(this.handleError))
    }
    getPaymentPlan(paymentId: any): Observable<any> {
        return this.http.get<any>(`${environment.API}payment-plan/plan-detail-history/${paymentId}?skip=0&limit=500`).pipe(catchError(this.handleError))
    }
    getUserDetailsOnDownPayPage(planId: any, userId: any): Observable<any> {
        return this.http.get<any>(`${environment.API}payment-plan/user/history/${planId}/${userId}?skip=0&limit=500`).pipe(catchError(this.handleError))
    }
    getPaymentPlanDetails(planId: any): Observable<any> {
        return this.http.get<any>(`${environment.API}payment-plan/register/users/${planId}?skip=0&limit=500`).pipe(catchError(this.handleError))
    }

    getAdminOrganizationsForPurchase(payload: { skip: number, limit: number, search: string }): Observable<any> {
        return this.http.get<any>(`${environment.API}organization/admin/access/filter?skip=${payload?.skip}&limit=${payload?.limit}&search=${payload?.search}`).pipe(catchError(this.handleError))
    }

    getPackageForPurchase(payload: { skip: number, limit: number, search: string }): Observable<any> {
        return this.http.get<any>(`${environment.API}product/package/list/for/filter?skip=${payload?.skip}&limit=${payload?.limit}&search=${payload?.search}`).pipe(catchError(this.handleError))
    }
    getPlanForPurchase(payload: { skip: number, limit: number, search: string }): Observable<any> {
        return this.http.get<any>(`${environment.API}payment-plan/list/for/filter?skip=${payload?.skip}&limit=${payload?.limit}&search=${payload?.search}`).pipe(catchError(this.handleError))
    }
    getEventForPurchase(payload: { skip: number, limit: number, search: string }): Observable<any> {
        return this.http.get<any>(`${environment.API}event-player/event/list/for/filter?skip=${payload?.skip}&limit=${payload?.limit}&search=${payload?.search}`).pipe(catchError(this.handleError))
    }

    getAllUsersForPurchase(payload: any): Observable<any> {
        return this.http.get<any>(`${environment.API}user-list?ordIds=${payload?.orgIds}&skip=${payload?.skip}&limit=${payload?.limit}&search=${payload?.search}`).pipe(catchError(this.handleError))
    }

    updatePackCount(payload: any): Observable<any> {
        return this.http.post<any>(`${environment.API}product/package-count`, payload).pipe(catchError(this.handleError))
    }

    updatePaymentPlanRemainingAmount(payload: any): Observable<any> {
        return this.http.post<any>(`${environment.API}payment-plan/update-recurring-subscription`, payload).pipe(catchError(this.handleError))
    }
}
