
enum EPurchaseHistoryTableType {
  PAYMENT_PLAN_HISTORY = "PAYMENT_PLAN_HISTORY",
  PURCHASE_PACKAGE_HISTORY = "PURCHASE_PACKAGE_HISTORY",
}

export {
  EPurchaseHistoryTableType
};

