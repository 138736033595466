import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: { [key: string]: boolean } = {};

  constructor() { }

  open(id: string) {
    this.modals[id] = true;
    if (id !== 'cropperModal') {
      document.body.classList.add('no-scroll');
    }
  }

  close(id: string) {
    this.modals[id] = false;
    const anyModalOpen = Object.values(this.modals).some(isOpen => isOpen);
    if (!anyModalOpen) {
      document.body.classList.remove('no-scroll');
    }
  }

  isOpen(id: string) {
    return this.modals[id];
  }
}