interface ITeamCoachManagerInfo { tempId?: number, id?: number, firstName: string; lastName: string; email: string; mobile: string; isParent: boolean }
interface IUserInfo {
  tempId?: number,
  id?: number,
  parentFirstName: string;
  parentLastName: string;
  parentEmail: string;
  parentMobile: string;
  athleteFirstName: string;
  athleteLastName: string;
  athleteEmail: string;
  athleteMobile: string;
  athleteDOB: string;
  athleteGrade: string | number;
  athletePosition: string;
  athleteJerseyNum: string;
  isUseParentEmail?: boolean;
  isUseParentMobile?: boolean;
  athleteFullName?: string;
  documentStatus?: string;
  weight?: number | string;
  rosterId?: number | string;
  parentId?: number | string;
}

enum EDocType {
  NOT_STARTED = "Not Started",
  PENDING = "In Progress",
  PENDING_1 = "PENDING",
  COMPLETE = "Completed",
  PAID = "Paid",
  FAIL = "FAILED"
}

export {
  EDocType, ITeamCoachManagerInfo,
  IUserInfo
};

