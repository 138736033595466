import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { formatCurrency, showAlertMessage } from '@app/core/helpers/utils';
import { MDModalModule } from '@app/shared/component/modals';
import { ImageCropperComponent } from '@app/shared/image-cropper/image-cropper.component';
import { EPurchaseHistoryTableType } from '@app/shared/types/purchase-history.type';
import { NgSelectModule } from '@ng-select/ng-select';
import { LucideAngularModule } from 'lucide-angular';
import { NgxMaskDirective } from 'ngx-mask';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-update-pack-modal',
  standalone: true,
  imports: [
    CommonModule,
    MDModalModule,
    FormsModule,
    ReactiveFormsModule,
    LucideAngularModule,
    NgSelectModule,
    NgxMaskDirective,
    ImageCropperComponent
  ],
  templateUrl: './update-pack-modal.component.html',
  styleUrl: './update-pack-modal.component.scss'
})
export class UpdatePackModalComponent {

  updateNoteForm!: FormGroup
  isFormSubmitted: boolean = false;
  PURCHASE_PACKAGE_HISTORY = EPurchaseHistoryTableType.PURCHASE_PACKAGE_HISTORY;
  PAYMENT_PLAN_HISTORY = EPurchaseHistoryTableType.PAYMENT_PLAN_HISTORY;

  @Input() selectedRow: any;
  @Input() tableType: any;
  @Output() closeModal = new EventEmitter<any>()

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
  ) {

  }

  ngOnInit(): void {
    this.updateNoteForm = new FormGroup({
      note: new FormControl('', [
        Validators.required
      ]),
      packCount: new FormControl(''),
      paymentPlanDiscountAmount: new FormControl(''),
    });
    if (this.selectedRow) {
      if (this.tableType === this.PURCHASE_PACKAGE_HISTORY) {
        this.updateNoteForm.patchValue({ packCount: this.selectedRow?.remainingPackCount });
      }
      // if (this.tableType === this.PAYMENT_PLAN_HISTORY) {
      //   this.updateNoteForm.patchValue({ paymentPlanDiscountAmount: this.selectedRow?.remainingAmount });
      // }
    }
    if (this.tableType === this.PURCHASE_PACKAGE_HISTORY) {
      this.updateNoteForm.get('packCount')?.setValidators([Validators.required, Validators.min(0)]);
    }
    if (this.tableType === this.PAYMENT_PLAN_HISTORY) {
      this.updateNoteForm.get('paymentPlanDiscountAmount')?.setValidators([Validators.required, Validators.min(0.01)]);
    }
  }

  get note() {
    return this.updateNoteForm && this.updateNoteForm.get('note')!;
  }

  get packCount() {
    return this.updateNoteForm && this.updateNoteForm.get('packCount')!;
  }

  get paymentPlanDiscountAmount() {
    return this.updateNoteForm && this.updateNoteForm.get('paymentPlanDiscountAmount')!;
  }

  handleSubmit() {
    if (this.updateNoteForm.invalid) {
      for (const control of Object.keys(this.updateNoteForm.controls)) {
        this.updateNoteForm.controls[control].markAsTouched();
      }
      this.isFormSubmitted = true;
      return;
    }
    this.isFormSubmitted = false;
    this.saveCommunity();
  }

  saveCommunity() {
    // const commonPayload = {
    //   note: this.note.value,
    // };
    this.handleCloseModal(true)
    // this.ngxSpinnerService.show();
    // this.sidebarService.addChildCommunity(commonPayload).subscribe((response: any) => {
    //   this.ngxSpinnerService.hide();
    //   if (response?.status) {
    //     this.handleCloseModal(true, response)
    //   } else {
    //     showAlertMessage(response.message ?? "Something went wrong!", "error");
    //   }
    // }, (error) => {
    //   this.ngxSpinnerService.hide();
    // })
  }

  handleCloseModal(isSubmit: boolean) {
    const resPayload = {
      isSubmit: isSubmit,
      reason: this.note.value,
      updatePackCount: this.packCount.value ?? '',
      updatedRemainingAmount: this.paymentPlanDiscountAmount.value ?? ''
    }
    this.closeModal.emit(resPayload)
  }

  handleChangePackCount(event: any) {
    if (event && this.selectedRow) {
      this.selectedRow = this.selectedRow;
      let remainingPackCount = event?.target?.value;
      if (Number(remainingPackCount) > Number(this.selectedRow?.packCount)) {
        event.target.value = this.selectedRow?.packCount;
        this.updateNoteForm.patchValue({ packCount: this.selectedRow?.packCount });
        showAlertMessage(`You can not add a number higher than the original package count of ${this.selectedRow?.packCount}`, "error");
        // this.updateNoteForm.get(['packCount'])?.setErrors({ forbiddenName: true });
        // this.updateNoteForm.get(['packCount'])?.updateValueAndValidity();
      } else {
        let inputValue: string = remainingPackCount;
        // Remove all non-digit characters
        inputValue = remainingPackCount?.replace(/\D/g, '');
        event.target.value = inputValue;
        this.updateNoteForm.patchValue({ packCount: inputValue });
      }
    }
  }

  handleChangeRemainingAmount(event: any) {
    if (event && this.selectedRow) {
      // Retrieve the input value
      let inputValue = event?.target?.value;
      if (inputValue) {
        // inputValue = parseFloat(inputValue?.replace(/,/g, ''));
        if (parseFloat(inputValue) > parseFloat(this.selectedRow?.remainingAmount)) {
          event.target.value = this.selectedRow?.remainingAmount;
          this.updateNoteForm.patchValue({ paymentPlanDiscountAmount: this.selectedRow?.remainingAmount });
          showAlertMessage(`You can not add an amount higher than the maximum remaining balance of ${formatCurrency(this.selectedRow?.remainingAmount)}`, "error");
        } else {
          event.target.value = inputValue;
          this.updateNoteForm.patchValue({ paymentPlanDiscountAmount: inputValue });
        }
      }
    }
  }
}
