import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { BehaviorSubject, Observable, catchError, throwError } from "rxjs";
import { showAlertMessage } from "../helpers/utils";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
  providedIn: 'root',
})
export class SidebarService {

  private menuItemsSubject = new BehaviorSubject<any[]>([]);
  menuItems$ = this.menuItemsSubject.asObservable();

  private featureItemsSubject = new BehaviorSubject<any[]>([]);
  featureItems$ = this.featureItemsSubject.asObservable();

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      showAlertMessage(error.error.message ?? "Something went wrong!", "error");
    } else {
      showAlertMessage(error ?? "Something went wrong!", "error");
    }
    return throwError(() => 'Something bad happened; please try again later.');
  }

  featureList(orgId: any): Observable<any> {
    return this.http.get(environment.API + 'features/list?orgId=' + orgId);
  }

  communityList(skip: any, take: any, search: any): Observable<any> {
    return this.http.get(environment.API + 'organization/public-organization?skip=' + 0 + '&limit=' + 100 + '&search=' + (search ? search : ''));
  }

  checkAccessCode(accessCode: string | number): Observable<any> {
    return this.http.get(environment.API + 'organization/with/access-code/' + accessCode);
  }

  getAllRoles(): Observable<any> {
    return this.http.get(environment.API + 'roles');
  }

  getAllRolesWithAdmin(): Observable<any> {
    return this.http.get(environment.API + 'all-roles');
  }

  getAllGrades(): Observable<any> {
    return this.http.get(environment.API + 'grade');
  }

  joinPrivateOrganization(res: any): Observable<any> {
    return this.http.post<any>(`${environment.API}organization-joining-request`, res)
  }

  viewMoreOrganizationOnTop(res: any): Observable<any> {
    return this.http.patch<any>(`${environment.API}update/user/time/${res?.id}`, '').pipe(catchError(this.handleError));
  }

  getAllStates(): Observable<any> {
    return this.http.get(environment.API + 'country/states');
  }

  getAllCities(stateCode: string): Observable<any> {
    return this.http.get(environment.API + `country/cities/${stateCode}`);
  }

  addChildCommunity(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.API}organization/sub-organization`, payload).pipe(catchError(this.handleError));
  }

  updateCommunity(subOrgId: number, payload: any): Observable<any> {
    return this.http.patch<any>(`${environment.API}/organization/sub-organization/${subOrgId}`, payload).pipe(catchError(this.handleError))
  }

  updateMenuItems(menuItems: any[]) {
    this.menuItemsSubject.next(menuItems);
  }

  updateFeatureItems(featureItems: any[]) {
    this.featureItemsSubject.next(featureItems);
  }

  activateDeactivateClient(payload: any): Observable<any> {
    payload["themeType"] = "theme1";
    return this.http.put<any>(`${environment.API}organization/active`, payload).pipe(catchError(this.handleError))
  }

}
