import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, catchError, throwError } from 'rxjs';
import { showAlertMessage } from '../helpers/utils';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class WebSettingsService {

  constructor(
    public http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) { }

  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      showAlertMessage(error.error.message ?? "Something went wrong!", "error");
    } else {
      showAlertMessage(error ?? "Something went wrong!", "error");
    }
    return throwError(() => 'Something bad happened; please try again later.');
  }

  updateWebSettingsContentDetails(payload: any): Observable<any> {
    payload["themeType"] = "theme1";
    return this.http.put<any>(`${environment.API}websetting/step`, payload).pipe(catchError(this.handleError))
  }

  updateWebSettingsActive(payload: any): Observable<any> {
    payload["themeType"] = "theme1";
    return this.http.put<any>(`${environment.API}webSetting/active`, payload).pipe(catchError(this.handleError))
  }

  WebSettingsDeactivateClient(payload: any): Observable<any> {
    payload["themeType"] = "theme1";
    return this.http.put<any>(`${environment.API}organization/active`, payload).pipe(catchError(this.handleError))
  }


  getWebSettingsContentDetails(id: number | string): Observable<any> {
    return this.http.get<any>(`${environment.API}websetting/${id}`).pipe(catchError(this.handleError))
  }

}
