import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, catchError, throwError } from 'rxjs';
import { showAlertMessage } from '../helpers/utils';
import { TokenStorageService } from './token-storage.service';
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    public http: HttpClient,
    private tokenStorageService: TokenStorageService
  ) { }

  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      showAlertMessage(error.error.message ?? "Something went wrong!", "error");
    } else {
      showAlertMessage(error ?? "Something went wrong!", "error");
    }
    return throwError(() => 'Something bad happened; please try again later.');
  }


  uploadFile(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload, payload?.name);

    return this.http.post<any>(`${environment.API}media/file`, formdata).pipe(catchError(this.handleError))
  }

  uploadDocument(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload, payload?.name);

    return this.http.post<any>(`${environment.API}media/document`, formdata).pipe(catchError(this.handleError))
  }

  uploadVideo(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload, payload?.name);

    return this.http.post<any>(`${environment.API}media/video-thumbnail`, formdata).pipe(catchError(this.handleError))
  }

  uploadProfileImage(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload, payload?.name);
    return this.http.post<any>(`${environment.API}media/profile-picture`, formdata).pipe(catchError(this.handleError))
  }

  uploadProfileImageWithUserId(payload: any, userId: number): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload, payload?.name);
    return this.http.post<any>(`${environment.API}media/profile-picture?userId=${userId}`, formdata).pipe(catchError(this.handleError))
  }

  uploadBulkDocument(payload: any): Observable<any> {
    const formdata = new FormData();
    for (let i = 0; i < payload.length; i++) {
      formdata.append('files', payload[i]);
    }
    return this.http.post<any>(`${environment.API}media/bulk/document`, formdata).pipe(catchError(this.handleError))
  }

  uploadCSVData(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload?.file);
    return this.http.post<any>(`${environment.API}media/upload-data?fileName=${payload?.fileName}`, formdata).pipe(catchError(this.handleError))
  }
  uploadCSVRoster(payload: any): Observable<any> {
    const formdata = new FormData();
    formdata.append("file", payload?.file);
    return this.http.post<any>(`${environment.API}media/update-team-data?teamId=${payload?.teamId}`, formdata).pipe(catchError(this.handleError))
  }

  downloadRosterTemplate(): Observable<any> {
    return this.http.get<any>(`${environment.API}media/download/roster/template`).pipe(catchError(this.handleError))
  }

  downloadRosterTemplateByTeam(teamId: any): Observable<any> {
    return this.http.get<any>(`${environment.API}team/roster-download/` + teamId).pipe(catchError(this.handleError))
  }

  downloadZip(payload: { documents: string[] | string }): Observable<any> {
    return this.http.post<any>(`${environment.API}media/download/document`, payload).pipe(catchError(this.handleError))
  }
  downloadUserByOrgId(orgId: any): Observable<any> {
    return this.http.get<any>(`${environment.API}user/download?orgIds=` + orgId).pipe(catchError(this.handleError))
  }

  downloadRegisteredEventUsers(eventId: number): Observable<any> {
    return this.http.get<any>(`${environment.API}event-player/athlete/download/${eventId}`).pipe(catchError(this.handleError))
  }

  downloadPurchasedPackages(orgId?: any, userId?: any): Observable<any> {
    const params: string[] = [];

    if (orgId) {
      params.push(`orgIds=${orgId}`);
    }

    if (userId) {
      params.push(`userIds=${userId}`);
    }

    const url = `${environment.API}/product/export/data/purchase/pack${params.length > 0 ? `?${params.join('&')}` : ''}`;

    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }
  downloadSingleEvents(orgId?: any, userId?: any): Observable<any> {
    const params: string[] = [];

    if (orgId) {
      params.push(`orgIds=${orgId}`);
    }

    if (userId) {
      params.push(`userIds=${userId}`);
    }

    const url = `${environment.API}/event-player/export/single/event/registration${params.length > 0 ? `?${params.join('&')}` : ''}`;

    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  downloadPaymentPlans(orgId?: any, userId?: any): Observable<any> {
    const params: string[] = [];

    if (orgId) {
      params.push(`orgIds=${orgId}`);
    }

    if (userId) {
      params.push(`userIds=${userId}`);
    }

    const url = `${environment.API}/payment-plan/export/purchase/history${params.length > 0 ? `?${params.join('&')}` : ''}`;

    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }
}
