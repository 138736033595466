import { createAction, createReducer, on, props } from '@ngrx/store';

// Define the initial state
export interface MenuState {
    menu: any
}
export const initialMenuState: MenuState = {
    menu: '',
};
// Define actions
export const storeMenuData = createAction(
    '[Menu] Set Payload',
    props<{ payload: MenuState }>()
);

export const removeMenuData = createAction('[Menu] Reset State');


// Create the reducer
const _menuReducer = createReducer(
    initialMenuState,
    on(storeMenuData, (state, { payload }) => ({ ...state, ...payload })),
    on(removeMenuData, () => initialMenuState)
);

export function menuReducer(state: any, action: any) {
    return _menuReducer(state, action);
}       