import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { WebSettingsService } from '@app/core/services/websettings.service';
import { NgStepperComponent, NgStepperModule } from 'angular-ng-stepper';
import { LUCIDE_ICONS, LucideAngularModule, LucideIconProvider, icons } from 'lucide-angular';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  selector: 'app-generate-client',
  standalone: true,
  imports: [CdkStepperModule, CommonModule, NgStepperModule, ReactiveFormsModule, LucideAngularModule, SlickCarouselModule],
  templateUrl: './generate-client.component.html',
  styleUrl: './generate-client.component.scss',
  providers: [{ provide: LUCIDE_ICONS, multi: true, useValue: new LucideIconProvider(icons) }]
})

export class GenerateClientComponent implements OnInit {

  @ViewChild('childNextButton')
  childNextButton!: ElementRef;

  @Output() customEvent = new EventEmitter<string>();


  @ViewChild(NgStepperComponent)
  ngStepper!: NgStepperComponent;
  // hideElement = true;
  generatePageForm!: FormGroup;
  @Input() clientID!: string;
  @Input() status!: string;
  isDeactive = true;
  isSuccess = false;

  constructor(private fb: FormBuilder, private webSettingsService: WebSettingsService,
  ) {

  }

  ngOnInit(): void {
  }

  step1Validation() {
    console.log('step 1 Validation Function');
    return true;
  }

  step2Validation() {
    console.log('step 2 Validation Function');
    return true;
  }

  handleClick() {
    //generate API call...
    const payload = {
      "organization": this.clientID,
      "isActive": !this.status
    }

    this.webSettingsService.updateWebSettingsActive(payload).subscribe((response: any) => {
      if (response?.status) {
        // pending to change response?.message - message to be generic from the API side;
        //this.showSaveSuccess('Details saved successfully');
        this.isSuccess = true;
        this.isDeactive = false;
        this.customEvent.emit('Custom event emitted from source component');
      }
    })
  }

  openExternalLink() {
    window.open('https://example.com', '_blank');
  }

}
