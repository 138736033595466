import { createAction, createReducer, on, props } from '@ngrx/store';

// Define the initial state
export interface EventState {
    event: any
}
export const initialEventState: EventState = {
    event: '',
};
// Define actions
export const storeEventData = createAction(
    '[Event] Set Payload',
    props<{ payload: EventState }>()
);

export const removeEventData = createAction('[Event] Reset State');


// Create the reducer
const _eventReducer = createReducer(
    initialEventState,
    on(storeEventData, (state, { payload }) => ({ ...state, ...payload })),
    on(removeEventData, () => initialEventState)
);

export function eventReducer(state: any, action: any) {
    return _eventReducer(state, action);
}       